import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

import { MarkdownService } from './markdown.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'markdown, [markdown]',
  template: '<ng-content></ng-content>',
  styles: [`:host /deep/ table{border-spacing:0;border-collapse:collapse;margin-bottom:16px}:host /deep/ table td,:host /deep/ table th{padding:6px 13px;border:1px solid #ddd}:host /deep/ table td[align=left],:host /deep/ table th[align=left]{text-align:left}:host /deep/ table td[align=center],:host /deep/ table th[align=center]{text-align:center}:host /deep/ table td[align=right],:host /deep/ table th[align=right]{text-align:right}:host /deep/ table tr:nth-child(2n){background-color:rgba(0,0,0,.03)}:host /deep/ blockquote{padding:0 1em;color:rgba(0,0,0,.535);border-left:.25em solid rgba(0,0,0,.11)}`],
})
export class MarkdownComponent implements AfterViewInit {
  private _data: string;
  private _src: string;

  @Input()
  get data(): string { return this._data; }
  set data(value: string) {
    this._data = value;
    this.render(value);
  }

  @Input()
  get src(): string { return this._src; }
  set src(value: string) {
    this._src = value;
    this.markdownService
      .getSource(value)
      .subscribe(
        markdown => {
          this.render(markdown);
          this.load.emit(markdown);
        },
        error => this.error.emit(error),
      );
  }

  @Output() error = new EventEmitter<string>();
  @Output() load = new EventEmitter<string>();

  get isTranscluded(): boolean {
    return !this.data && !this.src;
  }

  constructor(
    public element: ElementRef,
    public markdownService: MarkdownService,
  ) { }

  ngAfterViewInit() {
    if (this.isTranscluded) {
      this.render(this.element.nativeElement.innerHTML, true);
    }
  }

  render(markdown: string, decodeHtml = false) {
    this.element.nativeElement.innerHTML = this.markdownService.compile(markdown, decodeHtml);
    this.markdownService.highlight();
  }
}
